import React, {useEffect} from 'react';
import {Avatar, Button, Col, Descriptions, Input, InputNumber, message, Modal, Row, Space, Table} from 'antd';
import {distributionAmount, getUser, searchUsers, setUserState, UserInfo} from "../../apis/user";
import {
  CheckCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';

const {Search} = Input;

const columns = [
  {
    title: '头像',
    dataIndex: 'avatarURL',
    key: 'avatarURL',
  },
  {
    title: '昵称',
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: '注册时间',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
  }
];

interface UserTableItem {
  key: string;
  nickName: string
  avatarURL: React.ReactNode
  state: React.ReactNode
  operation?: React.ReactNode
  createdAt?: React.ReactNode
}

function User() {

  const [
    messageApi,
    contextHolder
  ] = message.useMessage();

  const [
    users,
    setUsers
  ] = React.useState<UserTableItem[]>([])

  const [
    distVisible,
    setDistVisible
  ] = React.useState(false)

  const [
    currentUser,
    setCurrentUser
  ] = React.useState<UserInfo>()

  const [
    count,
    setCount
  ] = React.useState<number>(0)

  const searchUser = async (value: string) => {
    if (value.length === 0) {
      messageApi.error("请输入用户ID或者昵称")
      return
    }
    const users = await searchUsers(value)
    if (users.length === 0) {
      messageApi.info("没有找到用户")
      return
    }
    const userItems: UserTableItem[] = []
    users.forEach((user: UserInfo) => {
      userItems.push({
        key: user.id,
        nickName: !!user.nickName ? user.nickName : '未定义',
        createdAt: new Date(user.createdAt).toLocaleString(),
        avatarURL: <Avatar src={user.avatarURL}>N</Avatar>,
        state: (() => {
          if (user.state === 'active') {
            return <CheckCircleOutlined style={{color: '#03a41d', fontSize: 20}}/>
          } else {
            return <CloseCircleOutlined style={{color: '#ff0000', fontSize: 20}}/>
          }
        })(),
        operation: <div>
          <Space wrap>
            <Button
              danger={user.state === 'active'}
              type={'primary'}
              onClick={async () => {
                if (user.state === 'active') {
                  await setUserState(user.id, 'forbidden')
                  messageApi.success('禁用成功')
                } else {
                  await setUserState(user.id, 'active')
                  messageApi.success('激活成功')
                }
                await searchUser(value)
              }}
            >
              {user.state === 'active' ? '禁用' : '激活'}
            </Button>
          </Space>
        </div>
      })
    })
    setUsers(userItems)
  }

  useEffect(() => {
    searchUser("ALL")
  }, [])

  return (
    <div>
      {contextHolder}
      <Row>
        <Col span={4}/>
        <Col span={16}>
          <Search size="large" placeholder="用户ID或者昵称" onSearch={searchUser} enterButton/>
          <Table
            style={{
              marginTop: '10px'
            }}
            dataSource={users}
            columns={columns}/>;
        </Col>
        <Col span={4}/>
      </Row>
      <Modal
        title="用户发次数"
        okText={'发卡'}
        cancelText={'取消'}
        open={distVisible}
        onOk={async () => {
          if (currentUser) {
            await distributionAmount(currentUser.id, count)
            messageApi.success('发卡成功')
            setDistVisible(false)
            setCount(0)
          } else {
            messageApi.error('用户不存在')
          }
        }}
        onCancel={() => {
          setDistVisible(false)
        }}
      >
        <Descriptions layout="vertical">
          <Descriptions.Item label="用户头像">
            <Avatar src={currentUser?.avatarURL}></Avatar>
          </Descriptions.Item>
          <Descriptions.Item label="用户昵称">{currentUser?.nickName}</Descriptions.Item>
          <Descriptions.Item label="用户ID">{currentUser?.id}</Descriptions.Item>
          <Descriptions.Item label="当前次数">
            {currentUser?.payment.balance}
          </Descriptions.Item>
          <Descriptions.Item
            label="注册时间">{new Date(currentUser?.createdAt as string).toLocaleString()}</Descriptions.Item>
        </Descriptions>

        <div>
          <Space>
            <InputNumber
              min={0}
              max={1000}
              value={count}
              onChange={(count) => {
                if (count) {
                  setCount(count)
                }
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                setCount(0)
              }}
            >
              重置
            </Button>
          </Space>
        </div>
      </Modal>
    </div>
  );
}

export default User;
