import {get, post, postFormData} from "./http";

interface AccessFile {
  accessURL: string
}

export const UploadFile = (data: FormData) => {
  return new Promise((resolve: (arg0: AccessFile) => void, reject) => {
    postFormData<AccessFile>('/admin/systems/upload_static_file', data).then((res) => {
      if (res.status === 200) {
        resolve(res.data)
      } else {
        reject(res.data)
      }
    })
  })
}

export const forwardGetURL = <D>(url: string) => {
  return post<D>(`/systems/forward_get_url?target_url=${url}`)
}