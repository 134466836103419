import {post} from "./http";

interface Redeem {
  code: string
}

export const getRedeem = (productID: string) => {
  return post<Redeem>('/admin/redeem_codes', {
    productID
  })
}