import {get, post} from "./http";
import {Role} from "./role";


export interface RoleKind {
  kindID: string;
  kindImageURL: string;
  kindName: string;
}

export const getRoleKinds = () => {
  return get<RoleKind[]>(`/admin/role_kinds`)
}

export const createRoleKind = (kindName: string, kindImageURL: string) => {
  return post('/admin/role_kinds', {
    kindName,
    kindImageURL
  })
}