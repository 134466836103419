import React, {useEffect} from 'react';
import {Button, Layout, Menu, theme, Typography} from 'antd';
import {
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import Home from "../Home/Home";
import User from "../User/User";
import Config from "../Config/Config";
import {Logged} from "../../apis/http";
import Role from "../Role/Role";
import {Product} from "../Product/Product";


const {Header, Content, Footer, Sider} = Layout;
const {Title} = Typography;

interface MenuItem {
  key: string;
  icon: React.ReactNode;
  label: string;
  node?: React.ReactNode;
}

const menuItems: MenuItem[] = [
  {
    key: '1',
    icon: <HomeOutlined/>,
    label: '首页',
    node: <Home/>,
  },
  {
    key: '2',
    icon: <UserOutlined/>,
    label: '用户',
    node: <User/>
  },
  {
    key: '3',
    icon: <SettingOutlined/>,
    label: '配置',
    node: <Config/>
  },
  {
    key: '4',
    icon: <SettingOutlined/>,
    label: '场景',
    node: <Role/>
  },
  {
    key: '5',
    icon: <SettingOutlined/>,
    label: '商品',
    node: <Product/>
  }
]

function App() {

  const [
    collapsed,
    setCollapsed
  ] = React.useState(false);

  const {
    token: {colorBgContainer}
  } = theme.useToken();

  const [
    children,
    setChildren
  ] = React.useState(menuItems[0].node);

  useEffect(() => {
    if (!Logged()) {
      window.location.href = '/login'
    }
  }, [])

  return (
    <Layout style={{height: '100vh'}}>
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={collapsed}
      >
        <Title
          level={4}
          style={{
            color: '#fff',
            textAlign: 'center',
          }}
        >{collapsed ? '🐱' : '文案猫🐱'}</Title>
        <Menu
          theme='dark'
          mode='inline'
          defaultSelectedKeys={[menuItems[0].key]}
          items={menuItems}
          onSelect={(item) => {
            menuItems.forEach((value) => {
              if (item.key === value.key) {
                setChildren(value.node)
              }
            })
          }}
        />
      </Sider>
      <Layout>
        <Header style={{padding: 0, backgroundColor: colorBgContainer}}>
          <Button
            type='text'
            icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            backgroundColor: colorBgContainer,
          }}
        >
          {children}
        </Content>
        <Footer style={{textAlign: 'center'}}>AI文案猫 ©2023</Footer>
      </Layout>
    </Layout>
  );
}

export default App;
