import {post, get, put} from "./http";

export interface AuthData {
  accessToken: string
  expireAt: string
  isNewUser: boolean
}

export interface UserInfo {
  id: string
  nickName: string
  avatarURL: string
  state: string
  createdAt: string
}

export interface UserInfo {
  id: string
  nickName: string
  avatarURL: string
  state: string
  createdAt: string
  payment: {
    balance: number
  }
}


export const larkAuth = (code: string, origin: string) => {
  return post<AuthData>('/admin/users/lark_grant', {code, redirectURI: `${origin}/login`})
}

export const searchUsers = (keyword: string) => {
  return get<UserInfo[]>(`/admin/users/search?keyword=${keyword}`)
}

export const getUser = (userID: string) => {
  return get<UserInfo>(`/admin/users/${userID}`)
}

export const distributionAmount = (userID: string, amount: number) => {
  return post('/admin/users/payments/distribution', {userID, amount})
}

export const setUserState = (userID: string, state: 'active' | 'forbidden') => {
  return put(`/admin/users/${userID}/states/${state}`)
}
