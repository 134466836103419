import {get, post, put} from "./http";

export interface Config {
  configID: string
  configType: string
  version: string
  configData: any
}

export const getConfig = (configType: string, version: string) => {
  return get<Config>(`/admin/configs/${configType}`, {
    "Client-Version": version
  })
}

export const getConfigs = () => {
  return get<Config[]>(`/admin/configs`, {})
}


export const setConfig = (configType: string, version: string, configData: any) => {
  return put(`/admin/configs/${configType}`, {
    configData: configData
  }, {
    "Client-Version": version
  })
}

export const createConfig = (configType: string, version: string, configData: any) => {
  return post(`/admin/configs`, {
    configType: configType,
    version: version,
    configData: configData
  })
}