import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Popconfirm, Select, Space} from "antd";
import {getConfig, getConfigs, setConfig, Config as ApiConfig, createConfig} from "../../apis/config";
import CodeMirror from "@uiw/react-codemirror";
import {json} from '@codemirror/lang-json';
import {okaidia} from '@uiw/codemirror-theme-okaidia';
import './index.css';

function Config() {

  const [
    messageApi,
    contextHolder
  ] = message.useMessage();

  const [
    configType,
    setConfigType
  ] = useState<string>('amount_config')

  const [
    version,
    setVersion
  ] = useState<string>('v4.0.0')

  const [
    content,
    setContent
  ] = useState<string>('')

  const [
    newVisible,
    setNewVisible
  ] = useState<boolean>(false)

  const [
    XConfigType,
    setXConfigType
  ] = useState<string>()

  const [
    XVersion,
    setXVersion
  ] = useState<string>()

  const [
    XContent,
    setXContent
  ] = useState<string>("{\n}")


  const [
    configs,
    setConfigs
  ] = useState<ApiConfig[]>([])


  const [
    versions,
    setVersions
  ] = useState<string[]>([])

  const [
    configTypes,
    setConfigTypes
  ] = useState<string[]>([])

  const refresh = async () => {
    try {
      const configs = await getConfigs()
      const versions: string[] = []
      for (const config of configs) {
        if (!versions.includes(config.version)) {
          versions.push(config.version)
        }
      }
      setVersions(versions)
      setConfigTypes(configs.filter((item) => {
        return item.version === version
      }).map((item) => {
        return item.configType
      }))
      let config = await getConfig(configType, version)
      setContent(JSON.stringify(config.configData, null, '\t'))
    } catch ({response: {data: {errcode, errmsg}}}) {
      messageApi.error(errmsg as string)
    }
  }

  useEffect(() => {
    refresh()
  }, [version, configType])

  return (
    <div>
      {contextHolder}
      <Space wrap>
        <Button type={"primary"} onClick={() => {
          setNewVisible(true)
        }}>新建配置</Button>
        <Select
          defaultValue={version}
          style={{width: 120}}
          onChange={(version) => {
            setVersion(version)
            setConfigTypes(configs.filter((item) => {
              return item.version === version
            }).map((item) => {
              return item.configType
            }))
          }}
          options={versions.map((item) => {
            return {value: item, label: item}
          })}
        />
        <Select
          defaultValue={configType}
          style={{width: 200}}
          onChange={(configType) => {
            setConfigType(configType)
          }}
          options={configTypes.map((item) => {
            return {value: item, label: item}
          })}
        />
      </Space>


      <CodeMirror
        style={{
          marginTop: '20px',
          fontFamily: "Monaco,Consolas, 'Courier New', monospace"
        }}
        value={content}
        onChange={(content) => {
          setContent(content)
        }}
        height={'60vh'}
        theme={okaidia}
        extensions={[json()]}
      >

      </CodeMirror>
      <Space wrap style={{
        marginTop: '20px'
      }}>
        <Popconfirm
          title="确认修改?"
          description="该操作会全量覆盖配置配置,请谨慎操作!"
          onConfirm={async () => {

            let parsed = null
            try {
              parsed = JSON.parse(content)
            } catch (e) {
              messageApi.error('JSON格式错误')
              return
            }

            if (typeof parsed === 'string' || !parsed) {
              messageApi.error('JSON格式错误')
            } else {
              try {
                await setConfig(configType, version, JSON.parse(content))
                await refresh()
                messageApi.success('修改成功')
              } catch (e) {
                messageApi.error('修改失败')
              }
            }
          }}
          onCancel={() => {
          }}
          okText="修改"
          cancelText="取消"
        >
          <Button
            danger={true}
            type={'primary'}
          >修改</Button>
        </Popconfirm>

      </Space>

      <Modal
        title={'添加新配置'}
        okText={'添加'}
        cancelText={'取消'}
        width={1000}
        open={newVisible}
        onOk={async () => {

          if (!XVersion?.includes("v")) {
            messageApi.error("配置版本格式错误")
            return
          }

          if (!XConfigType?.includes("_config")) {
            messageApi.error("配置类型格式错误")
            return
          }

          let parsed = null
          try {
            parsed = JSON.parse(XContent)
          } catch (e) {
            messageApi.error('JSON格式错误')
            return
          }

          if (typeof parsed === 'string' || !parsed) {
            messageApi.error('JSON格式错误')
          } else {
            try {
              await createConfig(XConfigType, XVersion, JSON.parse(XContent))
              await refresh()
              messageApi.success('创建成功')
              setNewVisible(false)
            } catch (e) {
              messageApi.error('创建失败')
            }
          }
        }}
        onCancel={() => {
          setNewVisible(false)
        }}
      >
        <Form>
          <Form.Item label={'配置版本'}>
            <Space wrap>
              <Input placeholder={'配置版本'} value={XVersion}
                     onChange={(item) => setXVersion(item.target.value)}></Input>
              <Button onClick={() => setXVersion("v4.0.0")}>V4.0.0</Button>
              <Button onClick={() => setXVersion("v5.0.0")}>V5.0.0</Button>
              <Button onClick={() => setXVersion("v6.0.0")}>V6.0.0</Button>
              <Button onClick={() => setXVersion("v7.0.0")}>V7.0.0</Button>
            </Space>
          </Form.Item>
          <Form.Item label={'配置类型'}>
            <Space wrap>
              <Input placeholder={'配置类型'} value={XConfigType}
                     onChange={(item) => setXConfigType(item.target.value)}></Input>
              <Button onClick={() => setXConfigType("index_config")}>index_config</Button>
            </Space>
          </Form.Item>
          <Form.Item label={'配置类型'}>
            <CodeMirror
              style={{
                marginTop: '20px',
                fontFamily: "Monaco"
              }}
              height={'40vh'}
              theme={okaidia}
              extensions={[json()]}
              value={XContent}
              onChange={(content) => {
                setXContent(content)
              }}
            >
            </CodeMirror>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Config;
