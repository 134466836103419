import {Button, Col, Form, Input, InputNumber, message, Modal, Row, Space, Table, Tag} from "antd";
import React, {useEffect} from "react";
import {ClearOutlined, FileAddOutlined, SwapRightOutlined} from "@ant-design/icons";

import {Checkbox, Divider} from 'antd';
import type {CheckboxChangeEvent} from 'antd/es/checkbox';
import type {CheckboxValueType} from 'antd/es/checkbox/Group';
import {createRole, delRole, getRoles} from "../../apis/role";
import {createProduct, delProduct, getProducts} from "../../apis/product";
import {getRedeem} from "../../apis/redeem";

const CheckboxGroup = Checkbox.Group;

const columns = [
  {
    title: '产品ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '产品名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '产品描述',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '原价',
    dataIndex: 'cost',
    key: 'cost',
  },
  {
    title: '现价',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: '使用次数',
    dataIndex: 'useCount',
    key: 'useCount',
  },
  {
    title: '生效时长',
    dataIndex: 'useDuration',
    key: 'useDuration',
  },
  {
    title: '可用平台',
    dataIndex: 'platform',
    key: 'platform',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
  }
];

interface ProductTableItem {
  id: string;
  name: string;
  description: string;
  cost: string;
  price: string;
  useCount: string;
  useDuration: string;
  platform: string;
  operation?: React.ReactNode
}


const MAX_COUNT = 1000000
const MAX_DURATION = 365 * 100

export function Product() {

  const [
    messageApi,
    contextHolder
  ] = message.useMessage();

  const [
    newVisible,
    setNewVisible
  ] = React.useState(false)

  const [
    products,
    setProducts
  ] = React.useState<ProductTableItem[]>([])

  const [
    productName,
    setProductName
  ] = React.useState<string>('')


  const [
    productDesc,
    setProductDesc
  ] = React.useState<string>('')

  const [
    cost,
    setCost
  ] = React.useState<string>('')

  const [
    price,
    setPrice
  ] = React.useState<string>('')

  const [
    useCount,
    setUseCount
  ] = React.useState<string>('')

  const [
    useDuration,
    setUseDuration
  ] = React.useState<string>('')


  const [
    selectedPlatform,
    setSelectedPlatform
  ] = React.useState<string[]>([])

  const onPlatformChange = (list: CheckboxValueType[]) => {
    setSelectedPlatform(list as string[])
  };

  const refresh = async () => {
    const products = await getProducts()

    setProducts(products.map((item) => {
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        cost: `${item.cost / 100} 元`,
        price: `${item.price / 100} 元`,
        useCount: item.useCount === MAX_COUNT ? '不限' : `${item.useCount}次`,
        useDuration: item.useDuration === 3153600000 ? '永久' : `${item.useDuration / (60 * 60 * 24)}天`,
        platform: item.platform,
        operation: (<div>
          <Space wrap>
            <Button
              danger
              type={"primary"}
              onClick={async () => {
                await delProduct(item.id)
                await refresh()
                messageApi.success("删除成功")
              }}
            >
              删除
            </Button>
            <Button
              type={"primary"}
              onClick={async () => {
                let res = await getRedeem(item.id)
                await navigator.clipboard.writeText(res.code)
                messageApi.success("兑换码已复制到粘贴板  ")
              }}
            >
              兑换码
            </Button
            >
          </Space>
        </div>)
      }
    }))
  }


  const createNewProduct = async () => {

    if (productName === "") {
      messageApi.error("产品名称不能为空")
      return
    }

    if (productDesc === "") {
      messageApi.error("产品描述不能为空")
      return
    }

    const rCost = parseFloat(cost)
    if (cost === "" || isNaN(rCost)) {
      messageApi.error("原价错误")
      return
    }


    const rPrice = parseFloat(price)
    if (price === "" || isNaN(rPrice)) {
      messageApi.error("现价错误")
      return
    }

    const rUseCount = parseInt(useCount)
    if (useCount === "" || isNaN(rUseCount)) {
      messageApi.error("次数错误")
      return
    }

    const rUseDuration = parseInt(useDuration)
    if (useDuration === "" || isNaN(rUseDuration)) {
      messageApi.error("天数错误")
      return
    }

    try {
      for (let i = 0; i < selectedPlatform.length; i++) {
        await createProduct(
          productName,
          productDesc,
          Math.round(rCost * 100),
          Math.round(rPrice * 100),
          Math.round(rUseCount),
          Math.round(rUseDuration * 60 * 60 * 24),
          selectedPlatform[i].toLowerCase(),
        )
      }
      messageApi.success("添加成功")
    } catch (e: any) {
      messageApi.success("添加失败")
    }
    await refresh()
    setNewVisible(false)
  }


  useEffect(() => {
    (async () => {
      await refresh()
    })()
  }, [])

  const plainOptions = ['Android', 'IOS', 'PC'];
  return (
    <div>
      {contextHolder}
      <Row>
        <Col span={2}/>
        <Col span={20}>
          <Space wrap>
            <Button type={"primary"} onClick={async () => {
              setNewVisible(true)
            }}>上架新产品</Button>
          </Space>
          <Table
            style={{marginTop: 20}}
            pagination={{pageSize: 8}}
            dataSource={products}
            columns={columns}/>;
        </Col>
        <Col span={2}/>
      </Row>
      <Modal
        title="添加新产品"
        okText={'上架'}
        cancelText={'取消'}
        open={newVisible}
        onOk={async () => {
          await createNewProduct()
        }}
        onCancel={() => {
          setNewVisible(false)
        }}
      >
        <Form>
          <Form.Item label={"产品名称"}>
            <Input
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder={'月卡'}
            />
          </Form.Item>
          <Form.Item label={"产品描述"}>
            <Input
              value={productDesc}
              onChange={(e) => setProductDesc(e.target.value)}
              placeholder={'永久次数'}
            />
          </Form.Item>
          <Form.Item label={"产品原价"}>
            <Input
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              placeholder={"单位元"}
            ></Input>
          </Form.Item>
          <Form.Item label={"产品现价"}>
            <Space>
              <Input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder={"单位元"}
              ></Input>
              <Button onClick={() => {
                setPrice(`0.99`)
              }}>0.99元</Button>
              <Button onClick={() => {
                setPrice(`1.99`)
              }}>1.99元</Button>
              <Button onClick={() => {
                setPrice(`3.99`)
              }}>3.99元</Button>
              <Button onClick={() => {
                setPrice(`19.9`)
              }}>19.9元</Button>
            </Space>
          </Form.Item>
          <Form.Item label={"使用次数"}>
            <Row>
              <Space>
                <Col>
                  <Input
                    value={useCount === `${MAX_COUNT}` ? '无限' : useCount}
                    onChange={(e) => setUseCount(e.target.value)}
                    placeholder={"购买产品可获得的次数"}
                  ></Input>
                </Col>
                <Col>
                  <Button
                    onClick={(e) => {
                      setUseCount(`${MAX_COUNT}`)
                    }}
                  >无限</Button>
                </Col>
              </Space>
            </Row>
          </Form.Item>
          <Form.Item label={"可用天数"}>
            <Space>
              <Input
                value={useDuration === `${MAX_DURATION}` ? '永久' : useDuration}
                onChange={(e) => setUseDuration(e.target.value)}
                placeholder={"购买产品可持续天数"}
              ></Input>
              <Button
                onClick={() => {
                  setUseDuration(`${1}`)
                }}
              >日</Button>
              <Button
                onClick={() => {
                  setUseDuration(`${7}`)
                }}
              >周</Button>
              <Button
                onClick={() => {
                  setUseDuration(`${30}`)
                }}
              >月</Button>
              <Button
                onClick={() => {
                  setUseDuration(`${MAX_DURATION}`)
                }}
              >永久</Button>
            </Space>

          </Form.Item>
          <Form.Item label={"生效平台"}>
            <CheckboxGroup options={plainOptions} onChange={onPlatformChange}></CheckboxGroup>
          </Form.Item>
        </Form>

      </Modal>
    </div>
  )
}