import React, {useEffect} from 'react';
import {Button, Checkbox, Col, Form, Input, Layout, Menu, message, Row, theme, Typography} from 'antd';
import {useLocation} from "react-router-dom";
import {larkAuth, AuthData} from "../../apis/user";
import {Logged, setAuth} from "../../apis/http";

const {Header, Content, Footer, Sider} = Layout;
const {Title} = Typography;

const CLIENT_ID = 'cli_a4093b734278500d';

function Login() {
  const location = useLocation();

  useEffect(() => {

    const checkSession = async function () {
      if (Logged()) {
        window.location.href = '/app'
        return
      }
      if (location.search.length > 0 && location.search.includes('code')) {
        const code = location.search.split('=')[1];
        try {
          const authData = await larkAuth(code, window.location.origin)
          setAuth(authData)
          window.location.href = '/app'
        } catch ({code}) {
          if (code === -1) {
            window.location.href = '/login'
            return
          } else {
            console.log(code)
          }
        }
      }
    }

    checkSession().then((err) => {
      console.log(err)
    })
  }, [])

  return (
    <Layout
      style={{
        height: '100vh',
        backgroundColor: '#fff'
      }}
    >
      <Header
        style={{
          backgroundColor: '#fff',
          minHeight: '20vh',
        }}
      >
        <Title level={1}>🐱</Title>
      </Header>
      <Content>
        <Row>
          <Col span={9}/>
          <Col
            span={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              type={"primary"}
              onClick={() => {
                const redirectURL = `${window.location.origin}/login`
                window.location.href = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${redirectURL}&response_type=code`
              }}
            >登录</Button>
          </Col>
          <Col span={9}/>
        </Row>

      </Content>
      <Footer></Footer>
    </Layout>
  )
}

export default Login;