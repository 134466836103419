import {del, get, post} from "./http";

interface RoleForm {
  Kind: string
  Maxlength: number
  Options: any
  Title: string
  Placeholder: string
  DefaultValue: string
}

interface Attribute {
  Rules: string[],
  Template: string,
  MaxWord: number,
  MinWord: number;
  Forms: RoleForm[];
}

export interface Role {
  roleID: string
  roleName: string
  roleDesc: string
  roleIconURL: string
  roleImageURL: string
  roleAttribute: Attribute
}


export const getRole = (roleID: string) => {
  return get<Role>(`/admin/roles/${roleID}`)
}

export const getRoles = () => {
  return get<Role[]>(`/admin/roles`)
}

export const delRole = (roleID: string) => {
  return del(`/admin/roles/${roleID}`)
}

export const createRole = (
  kindID: string,
  roleName: string,
  roleDesc: string,
  roleImageURL: string,
  roleIconURL: string,
  template: string,
  maxWord: number,
  minWord: number,
  rules: string[],
  titleFormID: string,
  roleForms: any
) => {
  return post(`/admin/roles`, {
    kindID,
    roleName,
    roleDesc,
    roleImageURL,
    roleIconURL,
    template,
    maxWord,
    minWord,
    rules,
    titleFormID,
    roleForms
  })
}