import {
  Button,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Upload
} from "antd";
import React, {useEffect} from "react";
import {createRole, delRole, getRole, getRoles, Role as RoleT} from "../../apis/role";
import {
  SwapRightOutlined,
  ClearOutlined,
  FileAddOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {createRoleKind, getRoleKinds, RoleKind} from "../../apis/roleKind";
import {UploadFile} from "../../apis/system";

const columns = [
  {
    title: 'ID',
    dataIndex: 'roleID',
    key: 'roleID',
    fixed: true,
    width: 80,
  },
  {
    title: '场景名称',
    dataIndex: 'roleName',
    key: 'roleName',
    fixed: true,
    width: 140,
  },
  {
    title: '场景描述',
    dataIndex: 'roleDesc',
    key: 'roleDesc',
    fixed: true,
    width: 200,
  },
  {
    title: '场景封面',
    dataIndex: 'roleImageURL',
    key: 'roleImageURL',
    fixed: true,
    width: 200,
  },
  {
    title: '场景图标',
    dataIndex: 'roleIconURL',
    key: 'roleIconURL',
    fixed: true,
    width: 100,
  },
  {
    title: '场景描述',
    dataIndex: 'roleDesc',
    key: 'roleDesc',
    fixed: true,
    width: 200,
  },
  {
    title: '规则',
    dataIndex: 'rules',
    key: 'rules',
    fixed: true,
    width: 200,
  },
  {
    title: '模板',
    dataIndex: 'template',
    key: 'template',
    fixed: true,
    width: 400,
  },
  {
    title: '最少字数',
    dataIndex: 'minWord',
    key: 'minWord',
    fixed: true,
    width: 60,
  },
  {
    title: '最多字数',
    dataIndex: 'maxWord',
    key: 'maxWord',
    width: 60,
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
  }
];

interface RoleTableItem {
  key: string
  roleID: string
  roleName: string
  roleDesc: string
  roleImageURL?: React.ReactNode
  roleIconURL?: React.ReactNode
  rules?: React.ReactNode
  template: string
  minWord: number
  maxWord: number
  operation?: React.ReactNode
}

interface RoleFromItem {
  formID: string;
  kind: string;
  title: string;
  placeholder: string;
  defaultValue: string;
  maxlength: number;
  options: string[]
}

interface FormTitle {
  title: string
  formID: string
}

function Role() {
  const [
    messageApi,
    contextHolder
  ] = message.useMessage();

  const [
    loading,
    setLoading
  ] = React.useState<boolean>(false)

  const [
    currentRole,
    setCurrentRole
  ] = React.useState<RoleT>()

  const [
    roleKinds,
    setRoleKinds
  ] = React.useState<RoleKind[]>([])


  const [
    roles,
    setRoles
  ] = React.useState<RoleTableItem[]>([])


  const [
    newVisible,
    setNewVisible
  ] = React.useState(false)

  const [
    newKindVisible,
    setNewKindVisible
  ] = React.useState(false)

  const [
    updateVisible,
    setUpdateVisible
  ] = React.useState(false)

  const [
    roleNames,
    setRoleNames
  ] = React.useState<string[]>([])

  const [
    role,
    setRole
  ] = React.useState<string>('')

  const [
    roleName,
    setRoleName
  ] = React.useState<string>('')

  const [
    roleDesc,
    setRoleDesc
  ] = React.useState<string>('')

  const [
    roleImageURL,
    setRoleImageURL
  ] = React.useState<string>('')

  const [
    roleIconURL,
    setRoleIconURL
  ] = React.useState<string>('')

  const [
    roleTemplate,
    setRoleTemplate
  ] = React.useState<string>('')


  const [
    roleMinWord,
    setRoleMinWord
  ] = React.useState<number>(0)

  const [
    roleMaxWord,
    setRoleMaxWord
  ] = React.useState<number>(1)


  /////////////////////

  const [
    formType,
    setFormType
  ] = React.useState<'textarea' | 'input' | 'tag'>('textarea')

  const [
    kindID,
    setKindID
  ] = React.useState<string>('')

  const [
    title,
    setTitle
  ] = React.useState<string>('')

  const [
    formID,
    setFormID
  ] = React.useState<string>('')


  const [
    placeholder,
    setPlaceholder
  ] = React.useState<string>('')

  const [
    defaultValue,
    setDefaultValue
  ] = React.useState<string>('')

  const [
    maxlength,
    setMaxlength
  ] = React.useState<number>(0)

  const [
    options,
    setOptions
  ] = React.useState<string>('')

  const [
    formTitles,
    setFormTitles
  ] = React.useState<FormTitle[]>([])

  const [
    titleFormID,
    setTitleFormID
  ] = React.useState<string>('')

  const [
    roleForms,
    setRoleForms
  ] = React.useState<RoleFromItem[]>([])
  /////////////////////

  const [
    kindName,
    setKindName
  ] = React.useState<string>('')

  const [
    kindImageURL,
    setKindImageURL
  ] = React.useState<string>('')


  ///////////////////////////

  const refresh = async () => {
    const userItems: RoleTableItem[] = []
    let roleItems = await getRoles()
    roleItems.forEach((item) => {
      userItems.push({
        key: item.roleID,
        roleID: item.roleID,
        roleName: item.roleName,
        roleDesc: item.roleDesc,
        roleImageURL: (
          <Image src={item.roleImageURL}></Image>
        ),
        roleIconURL: (
          <Image src={item.roleIconURL}></Image>
        ),
        rules: <Space size={[0, 'small']} wrap>
          {
            item.roleAttribute.Rules.map((rule) => {
              return <Tag bordered={false}>{rule}</Tag>
            })
          }
        </Space>,
        maxWord: item.roleAttribute.MaxWord,
        minWord: item.roleAttribute.MinWord,
        template: item.roleAttribute.Template,
        operation: (<div>
          <Space wrap>
            <Button
              danger
              type={"primary"}
              onClick={async () => {
                await delRole(item.roleID)
                await refresh()
                messageApi.success("删除成功")
              }}
            >
              删除
            </Button>
            {/*<Button*/}
            {/*  type={"primary"}*/}
            {/*  onClick={async () => {*/}
            {/*    const role = await getRole(item.roleID)*/}
            {/*    setCurrentRole(role)*/}
            {/*    setUpdateVisible(true)*/}
            {/*    setRoleImageURL(role.roleImageURL)*/}
            {/*    setRoleIconURL(role.roleIconURL)*/}
            {/*  }}*/}
            {/*>*/}
            {/*  编辑*/}
            {/*</Button>*/}
          </Space>
        </div>)
      })
    })
    setRoles(userItems)
    setRoleKinds(await getRoleKinds())
  }

  const createNewRole = async () => {

    if (roleName === "") {
      messageApi.error("场景名称不能为空")
      return
    }

    if (roleDesc === "") {
      messageApi.error("场景描述不能为空")
      return
    }

    if (roleTemplate === "") {
      messageApi.error("场景模板不能为空")
      return
    }

    if (!roleTemplate.includes("{.")) {
      messageApi.error("场景模板必须包含{.}")
      return
    }


    if (roleMinWord > roleMaxWord) {
      messageApi.error("最少字数不能大于最多字数")
      return
    }

    if (roleImageURL === "") {
      messageApi.error("封面地址为空")
      return
    }

    if (roleIconURL === "") {
      messageApi.error("图标地址为空")
      return
    }

    if (kindID === "") {
      messageApi.error("类型为空")
      return
    }

    await createRole(
      kindID,
      roleName,
      roleDesc,
      roleImageURL,
      roleIconURL,
      roleTemplate,
      roleMaxWord,
      roleMinWord,
      roleNames,
      titleFormID,
      roleForms
    )
    messageApi.success("添加成功")
    await refresh()
    setNewVisible(false)
  }

  const createNewRoleKind = async () => {
    if (kindName === "") {
      messageApi.error("分类名称不能为空")
      return
    }

    if (kindImageURL === "") {
      messageApi.error("分类封面不能为空")
      return
    }

    await createRoleKind(kindName, kindImageURL)
    messageApi.success("添加成功")
    await refresh()
    setNewKindVisible(false)
  }

  useEffect(() => {
    (async () => {
      await refresh()
    })()
  }, [])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  );

  return <div>
    {contextHolder}
    <Row>
      <Col span={1}/>
      <Col span={22}>
        <Space wrap>
          <Button type={"primary"} onClick={async () => {
            setNewVisible(true)
          }}>添加新场景</Button>
          <Button type={"primary"} onClick={async () => {
            setNewKindVisible(true)
          }}>添加新分类</Button>
        </Space>
        <Table
          style={{marginTop: 20, height: "60vh"}}
          pagination={{pageSize: 5}}
          dataSource={roles}
          columns={columns}/>;
      </Col>
      <Col span={1}/>
    </Row>

    <Modal
      title={'修改场景'}
      okText={'修改'}
      cancelText={'取消'}
      open={updateVisible}
      onOk={async () => {

      }}
      onCancel={() => {
        setUpdateVisible(false)
      }}
    >
      <Form>
        <Form.Item label={"场景分类"}>
          <Select
            defaultValue={'无选项'}
            style={{width: 120}}
            onChange={(e) => setKindID(e)}
            options={roleKinds.map((item) => {
              return {value: item.kindID, label: item.kindName}
            })}
          />
        </Form.Item>
        <Form.Item label={"场景名称"}>
          <Input
            value={currentRole?.roleName}
            onChange={(e) => setRoleName(e.target.value)}
            placeholder={'例如小红书文案'}
          />
        </Form.Item>
        <Form.Item label={"场景描述"}>
          <Input
            value={currentRole?.roleDesc}
            onChange={(e) => setRoleDesc(e.target.value)}
            placeholder={'描述场景用户'}
          />
        </Form.Item>

        <Form.Item label={"场景封面"}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            customRequest={async (e) => {
              const file = e.file as File
              const form = new FormData();
              form.set("file", file)
              form.set("name", file.name)
              const res = await UploadFile(form)
              setRoleImageURL(res.accessURL)
            }}
          >
            {roleImageURL ? <img src={roleImageURL} alt="avatar" style={{width: '100%'}}/> : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item label={"场景图标"}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            customRequest={async (e) => {
              const file = e.file as File
              const form = new FormData();
              form.set("file", file)
              form.set("name", file.name)
              const res = await UploadFile(form)
              setRoleIconURL(res.accessURL)
            }}
          >
            {roleIconURL ? <img src={roleIconURL} alt="avatar" style={{width: '100%'}}/> : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item label={"场景模板"}>
          <Input
            placeholder={'场景描述，必须要有{.}'}
            value={currentRole?.roleAttribute.Template}
            onChange={(e) => setRoleTemplate(e.target.value)}
          />
        </Form.Item>
        <Form.Item label={"字数范围"}>
          <InputNumber value={currentRole?.roleAttribute.MinWord} onChange={(value) => {
            if (value) {
              setRoleMinWord(value)
            }
          }} style={{marginRight: 10}} placeholder={'最小值'}></InputNumber> <SwapRightOutlined/>
          <InputNumber
            value={currentRole?.roleAttribute.MaxWord}
            onChange={(value) => {
              if (value) {
                setRoleMaxWord(value)
              }
            }}
            style={{marginLeft: 10}}
            placeholder={'最大值'}></InputNumber>
        </Form.Item>
      </Form>


    </Modal>

    <Modal
      title={'添加新类型'}
      okText={'添加'}
      cancelText={'取消'}
      open={newKindVisible}
      onOk={async () => {
        await createNewRoleKind()
        setKindImageURL('')
        setKindName('')
      }}
      onCancel={() => {
        setNewKindVisible(false)
        setKindImageURL('')
        setKindName('')
      }}
    >
      <Form>
        <Form.Item label={"分类名称"}>
          <Input
            value={kindName}
            onChange={(e) => setKindName(e.target.value)}
            placeholder={'热门'}
          ></Input>
        </Form.Item>
        <Form.Item label={'分类图标'}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            customRequest={async (e) => {
              const file = e.file as File
              const form = new FormData();
              form.set("file", file)
              form.set("name", file.name)
              const res = await UploadFile(form)
              setKindImageURL(res.accessURL)
              await refresh()
            }}
          >
            {kindImageURL ? <img src={kindImageURL} alt="avatar" style={{width: '100%'}}/> : uploadButton}
          </Upload>
        </Form.Item>
      </Form>

    </Modal>

    <Modal
      title="添加新场景"
      okText={'添加'}
      cancelText={'取消'}
      open={newVisible}
      width={1000}
      onOk={async () => {
        await createNewRole()
      }}
      onCancel={() => {
        setNewVisible(false)
      }}
    >
      <Form>
        <Form.Item label={"场景分类"}>
          <Select
            defaultValue={'无选项'}
            style={{width: 120}}
            onChange={(e) => setKindID(e)}
            options={roleKinds.map((item) => {
              return {value: item.kindID, label: item.kindName}
            })}
          />
        </Form.Item>
        <Form.Item label={"场景名称"}>
          <Input
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            placeholder={'例如小红书文案'}
          />
        </Form.Item>
        <Form.Item label={"场景描述"}>
          <Input
            value={roleDesc}
            onChange={(e) => setRoleDesc(e.target.value)}
            placeholder={'描述场景用户'}
          />
        </Form.Item>
        <Form.Item label={"场景封面"}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            customRequest={async (e) => {
              const file = e.file as File
              const form = new FormData();
              form.set("file", file)
              form.set("name", file.name)
              const res = await UploadFile(form)
              setRoleImageURL(res.accessURL)
              await refresh()
            }}
          >
            {roleImageURL ? <img src={roleImageURL} alt="avatar" style={{width: '100%'}}/> : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item label={"场景图标"}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            customRequest={async (e) => {
              const file = e.file as File
              const form = new FormData();
              form.set("file", file)
              form.set("name", file.name)
              const res = await UploadFile(form)
              setRoleIconURL(res.accessURL)
              await refresh()
            }}
          >
            {roleIconURL ? <img src={roleIconURL} alt="avatar" style={{width: '100%'}}/> : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item label={"场景规则"}>
          <Row>
            <Col span={18}>
              <Input placeholder={'描述场景规则'} value={role} onChange={(e) => {
                setRole(e.target.value)
              }}/>
            </Col>
            <Col span={6} style={{display: 'flex', justifyContent: 'right'}}>
              <Space wrap>
                <Button icon={<ClearOutlined/>} danger type={'primary'} onClick={() => {
                  setRoleNames([])
                }}></Button>
                <Button icon={<FileAddOutlined/>} type={'primary'} onClick={() => {
                  if (role === '') {
                    messageApi.warning("规则不能为空")
                    return
                  }
                  setRoleNames([...roleNames, role])
                  setRole('')
                }}></Button>
              </Space>
            </Col>
          </Row>
          <Row style={{marginTop: 10}}>
            <Col span={24}>
              {
                roleNames.map((name) => {
                  return <Tag color={'success'}>{name}</Tag>
                })
              }
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label={"场景模板"}>
          <Input
            placeholder={'场景描述，必须要有{.}'}
            value={roleTemplate}
            onChange={(e) => setRoleTemplate(e.target.value)}
          />
        </Form.Item>
        <Form.Item label={"字数范围"}>
          <InputNumber value={roleMinWord} onChange={(value) => {
            if (value) {
              setRoleMinWord(value)
            }
          }} style={{marginRight: 10}} placeholder={'最小值'}></InputNumber> <SwapRightOutlined/>
          <InputNumber
            value={roleMaxWord}
            onChange={(value) => {
              if (value) {
                setRoleMaxWord(value)
              }
            }}
            style={{marginLeft: 10}}
            placeholder={'最大值'}></InputNumber>
        </Form.Item>
        <Form.Item label={"添加表单"}>
          <Row>
            <Space wrap>
              <Col>
                <Select
                  defaultValue={formType}
                  style={{width: 120}}
                  onChange={(e) => setFormType(e)}
                  options={[
                    {value: 'textarea', label: '长文本'},
                    {value: 'input', label: '短文本'},
                    {value: 'tag', label: '标签选择'}
                  ]}
                />
              </Col>

              <Col span={100}>
                <Input
                  placeholder={'标题'}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                ></Input>
              </Col>

              <Col span={100}>
                <Input
                  placeholder={'FormID'}
                  value={formID}
                  onChange={(e) => setFormID(e.target.value)}
                ></Input>
              </Col>

              {formType === 'input' || formType === 'textarea' ? <Col span={100}>
                <Input
                  placeholder={'占位文本'}
                  value={placeholder}
                  onChange={(e) => setPlaceholder(e.target.value)}
                ></Input>
              </Col> : ''}


              {formType === 'input' || formType === 'textarea' ? <Col span={100}>
                <InputNumber
                  placeholder={'最大长度'}
                  value={maxlength}
                  onChange={(value) => {
                    if (value) {
                      setMaxlength(value)
                    }
                  }}
                ></InputNumber>
              </Col> : ''}


              {formType === 'tag' ? <Col span={100}>
                <Input
                  placeholder={'默认值'}
                  value={defaultValue}
                  onChange={(e) => setDefaultValue(e.target.value)}
                ></Input>
              </Col> : ''}

              {formType === 'tag' ? <Col span={120}>
                <Input
                  placeholder={'Tag选项,使用英文逗号分割'}
                  value={options}
                  onChange={(e) => setOptions(e.target.value)}
                ></Input>
              </Col> : ''}
              <Col>
                <Button icon={<FileAddOutlined/>} type={'primary'} onClick={() => {

                  if (title === "") {
                    messageApi.error("标题为必须")
                    return
                  }

                  if (formID === "") {
                    messageApi.error("FormID为必须")
                    return
                  }


                  if (formType === 'input' || formType === 'textarea') {
                    if (placeholder === "") {
                      messageApi.error("占位为必须")
                      return
                    }

                    if (maxlength <= 10) {
                      messageApi.error("最大长度小于10")
                      return
                    }
                  } else {
                    if (defaultValue === "") {
                      messageApi.error("默认值为必须")
                      return
                    }

                    if (options.split(",").length < 2) {
                      messageApi.error("选项为必须")
                      return
                    }
                  }

                  setRoleForms(roleForms.concat({
                    formID: formID,
                    kind: formType,
                    title: title,
                    placeholder: placeholder,
                    defaultValue: defaultValue,
                    maxlength: maxlength,
                    options: options.split(',')
                  }))


                  setFormTitles(roleForms.map((item) => {
                    return {
                      title: item.title,
                      formID: item.formID
                    }
                  }))
                  setTitle('')
                  setFormID('')
                  setMaxlength(0)
                  setDefaultValue('')
                  setOptions('')
                }}></Button>
              </Col>
            </Space>
          </Row>
          <Row>
            {
              roleForms.map((item) => {
                return (
                  <div>
                    [{item.kind} | {item.title} | {item.placeholder} | {item.defaultValue} | {item.maxlength} | {item.options}]
                    <Button type={"link"} onClick={() => {
                      setRoleForms(roleForms.filter((event) => {
                        return event.formID !== item.formID
                      }))
                    }}>删除</Button>
                  </div>
                )
              })
            }
          </Row>
        </Form.Item>
        <Form.Item label={"场景模板"}>
          <Select
            defaultValue={'无选项'}
            style={{width: 120}}
            onChange={(e) => setTitleFormID(e)}
            options={formTitles.map((item) => {
              return {value: item.formID, label: item.title}
            })}
          />
        </Form.Item>
      </Form>

    </Modal>
  </div>
}

export default Role;