import {del, get, post} from "./http";

export interface Product {
    id: string;
    name: string;
    description: string;
    cost: number;
    price: number;
    available: boolean;
    useCount: number;
    useDuration: number;
    platform: string
}

interface CreateProduct {
    name: string;
    description: string;
    cost: number;
    price: number;
    useCount: number;
    useDuration: number;
    platform: string
}


export const getProduct = (productID: string) => {
    return get<Product>(`/admin/products/${productID}`)
}

export const delProduct = (productID: string) => {
    return del<Product>(`/admin/products/${productID}`)
}

export const getProducts = () => {
    return get<Product[]>(`/admin/products`)
}


export const createProduct = (
    name: string,
    description: string,
    cost: number,
    price: number,
    useCount: number,
    useDuration: number,
    platform: string,
) => {
    return post(`/admin/products`, {
        name,
        description,
        cost,
        price,
        useCount,
        useDuration,
        platform,
    })
}