import React, {useEffect, useState} from 'react';
import Title from "antd/es/typography/Title";
import {Image} from "antd";
import {forwardGetURL} from "../../apis/system";

interface Image {
  imgurl: string
}

function Home() {

  const [title,setTitle] = useState('')
  const [imageSrc,setImageSrc] = useState('https://image.baidu.com/search/down?url=https://tvax3.sinaimg.cn//large/0072Vf1pgy1fodqn2e7bvj318g0p0qv5.jpg')

  useEffect(() => {

    (async () => {
      const res = await forwardGetURL<string>('https://api.mcloc.cn/words/')
      setTitle(res)
      const image = await forwardGetURL<Image>('https://www.dmoe.cc/random.php?return=json')
      setImageSrc(image.imgurl)
    })()

  },[])

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      placeItems: "center"
    }}>
      <Title>{title}</Title>
      <Image src={imageSrc} height={800}></Image>
    </div>
  );
}

export default Home;
