import axios from 'axios'
import {AuthData} from "./user";

const baseURL = 'https://api-fox.wisdome-lake.com/api/v1'

export const Instance = axios.create({
  baseURL: baseURL
})


export const setAuth = (data: AuthData) => {
  Instance.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`
  localStorage.setItem("authData", JSON.stringify(data))
}

export const removeToken = () => {
  delete Instance.defaults.headers.common['Authorization']
  localStorage.removeItem("authData")
}

export const Logged = () => {
  const item = localStorage.getItem("authData");
  if (item) {
    const authData = JSON.parse(item) as AuthData
    console.log(authData.expireAt)
    return Date.parse(authData.expireAt) >= Date.now();
  } else {
    return false;
  }
}

const Http = <D>(method: string, url: string, params?: any, headers?: any) => {
  return new Promise((resolve: (arg0: D) => void, reject: (arg0: any) => void) => {
    if (!Instance.defaults.headers.common['Authorization'] && url !== '/admin/users/lark_grant') {
      const item = localStorage.getItem("authData");
      if (item) {
        const authData = JSON.parse(item) as AuthData
        if (Date.parse(authData.expireAt) <= Date.now()) {
          removeToken()
          reject({'code': -1})
          return;
        } else {
          setAuth(authData)
        }
      } else {
        reject({'code': -1})
        return;
      }
    }
    Instance.request<D>({
      url: url,
      method: method,
      headers: headers,
      data: params,
    }).then((res) => {
      if (res.status < 300) {
        resolve(res.data)
      } else {
        if (res.status === 401) {
          removeToken()
          Instance.defaults.headers.common['Authorization'] = null
          reject({'code': -1})
        }
        reject(res.data)
      }
    }).catch((err) => {
      reject(err)
    })
  })
}

export const get = <D>(url: string, headers?: any) => {
  return Http<D>('get', url, {}, headers)
}

export const post = <D>(url: string, params?: any, headers?: any) => {
  return Http<D>('post', url, params, headers)
}

export const postFormData = <D>(url: string, data?: any) => {
  const item = localStorage.getItem("authData");
  if (item) {
    const authData = JSON.parse(item) as AuthData
    return axios<D>({
      baseURL: baseURL,
      url: url,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authData.accessToken}`
      },
      data
    })
  } else {
    return Promise.reject("")
  }
}


export const put = <D>(url: string, params?: any, headers?: any) => {
  return Http<D>('put', url, params, headers)
}

export const del = <D>(url: string, headers?: any) => {
  return Http<D>('delete', url, {}, headers)
}